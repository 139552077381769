import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardContent, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { purple } from "@mui/material/colors";

const LogoutButton = ({setDisplayedPage, selectedModel, setSelectedModel, user}) => {
  const { logout } = useAuth0();
  const authorizedEmails = ["shahruj@aiseer.co", "dennis@aiseer.co","keefe@aiseer.co", "von@aiseer.co","sriram@aiseer.co","aarush@aiseer.co","eshaan@aiseer.co","markfrankel29@gmail.com","siok2x@gmail.com"];
  const isAuthorized = authorizedEmails.includes(user.email);

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
    console.log(selectedModel)
  };


  return (
    <Card 
      sx={{
        boxShadow: 0, // Remove any shadow for flat design
        border: 'none', // No border for the card
        backgroundColor: '#0A0A0A', // Light purple background for the card
      }}
    >
      <CardContent>
        <Button
          onClick={() => logout({ returnTo: window.location.origin })}
          variant="contained"
          fullWidth
          style={{
            marginBottom: '5px',
            backgroundColor: 'rgba(116, 7, 255, 0.2)', // Darker purple button
            color: '#fff', // White text
            boxShadow: 'none' // Ensure button is flat
          }}
        >
          Log Out
        </Button>
        <br></br>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwuYw6Euk1oo4BFb3Gq5cvtVYvfpKW5C-3_ED_IhPD76aWRg/viewform">
          <Button
            variant="contained"
            fullWidth
            style={{
              marginBottom: '5px',
              backgroundColor: 'rgba(116, 7, 255, 0.2)', // Darker purple button
              color: '#fff', // White text
              boxShadow: 'none' // Ensure button is flat
            }}
          >
            Feedback
          </Button>
        </a>
        <br></br>
        <Button
            variant="contained"
            fullWidth
            onClick={()=>{setDisplayedPage('profile')}}
            style={{
              marginBottom: '5px',
              backgroundColor: 'rgba(116, 7, 255, 0.2)', // Darker purple button
              color: '#fff', // White text
              boxShadow: 'none' // Ensure button is flat
            }}
          >
            Profile
        </Button>
        {/* <Button
            variant="contained"
            fullWidth
            onClick={()=>{setDisplayedPage('pricing')}}
            style={{
              backgroundColor: purple[200], // Darker purple button
              color: '#fff', // White text
              boxShadow: 'none' // Ensure button is flat
            }}
          >
            Pricing
        </Button> */}
        <Button
            variant="contained"
            fullWidth
            onClick={()=>{setDisplayedPage('home')}}
            style={{
              backgroundColor: 'rgba(116, 7, 255, 0.2)', // Darker purple button
              color: '#fff', // White text
              boxShadow: 'none' // Ensure button is flat
            }}
          >
            Home
        </Button>
        {/* <FormControl fullWidth variant="outlined" style={{ marginTop: '1rem' }}>
          <InputLabel id="model-select-label">Select Model</InputLabel>
          <Select
            labelId="model-select-label"
            value={selectedModel}
            onChange={handleModelChange}
            label="Select Model"
            style={{
              backgroundColor: purple[100], // Light purple background for the dropdown
              color: '#000', // Black text
            }}
          >
            <MenuItem value={"Gemini Pro"}>Gemini Pro</MenuItem>
            <MenuItem value={"GPT 4o"}>GPT 4o</MenuItem>
            {isAuthorized && <MenuItem value={"FacTcT-1"}>FacTcT-1</MenuItem>}
          </Select>
        </FormControl> */}
      </CardContent>
    </Card>
  );
};

export default LogoutButton;
