import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Container, Grid, Button, containerClasses, Badge, Skeleton} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from 'framer-motion';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import MicNoneIcon from '@mui/icons-material/MicNone';
import Permissions from './Permissions';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingComponent from './loadingComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@mui/system';
import ExpandComponent from './clicktoexpand';
import ImageSlider from './imageslider';
import SearchIcon from '@mui/icons-material/Search';
import Fab from '@mui/material/Fab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoginButton from './LoginButton';
import Profile from './Profile';
import { useAuth0 } from "@auth0/auth0-react";
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import {Modal, Backdrop, Fade } from '@mui/material';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import PizZip from "pizzip";
import { v4 as uuidv4 } from 'uuid';
import VideoCarousel from './video-examples';
import ProfilePage from './ProfilePage/profilePage';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Loader from './DocLoader';
import TextLoader from './TextLoader';
import { setPara } from 'pdfjs-dist/build/pdf.worker';
import SignupForm from './signup-form';
import AccountProfile from './AccountProfile';
import PricingPage from './Pricing';
import * as XLSX from 'xlsx';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { createAndCheckTask } from './runasyncqueries';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400
import "@fontsource/ibm-plex-sans/400.css"; // Specify weight
import "@fontsource/ibm-plex-sans/400-italic.css"; // Specify weight and style
import LoginModal from './LoginModal';
import VideoParagraphComponent from './videoExpand';
import SimpleClaimComponent from './placeholder';
import EmailConfirmation from './EmailConfirmation';
//functions for docTable start
function createData(id, claimFact, verdict, assessment, source) {
  return {
    id,
    claimFact,
    verdict,
    assessment,
    source,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}





function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'claimFact',
    numeric: false,
    disablePadding: true,
    label: 'Claim/Fact',
  },
  {
    id: 'verdict',
    numeric: false,
    disablePadding: false,
    label: 'Verdict',
  },
  {
    id: 'assessment',
    numeric: false,
    disablePadding: false,
    label: 'Assessment',
  },
  {
    id: 'source',
    numeric: false,
    disablePadding: false,
    label: 'Source',
  },
];

//function for docTable end

// import SignupForm from '../../../FacticityPlatform/src/signup-form';
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black', // Change this to your desired outline color
      
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '24px', // Input text size
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '15px', // Placeholder text size
  },
});

function HideScrollbar() {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      ::-webkit-scrollbar {
        display: none;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return null;
}

const App = () => {
  const [transcripts, setTranscripts] = useState([]);
  const [output, setOutput] = useState([])
  const [isListening, setIsListening] = useState(false);
  const speechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
  const [index, setIndex] = useState(0)
  const [selectedClaim, setSelectedClaim] = useState(0)
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [count, setCount] = useState(0);
  const [docFactCount, setDocFactCount] = useState(0); //same as count, but to track transcripts of docs
  const [language, Setlanguage] = useState('en-SG');
  const [singleQuerymode, SetsingleQuerymode] = useState(true)
  const [factCounter,setFactCounter] = useState(0)
  const [factlimit, setfactlimit] = useState(10)
  const [position, setPosition] = useState([])
  const [copyStyle, setCopyStyle] = useState({display: 'none'});
  const [copyText, setCopyText] = useState("Copy claim");
  const [fileProcessLoad, setFileProcessLoad] = useState(false);
  const [docsProcessLoad, setDocsProcessLoad] = useState({}); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [requestId, setRequestId] = useState([])
  const [docTranscripts, setDocTranscripts] = useState([])
  const [docOutput, setDocOutput] = useState([])
  const [uploadedDocs, setUploadedDocs] = useState({})
  const [uploadedDocCount, setUploadedDocCount] = useState(0)
  const [videos, setVideos] = useState([]);
  const [manualReload, setManualReload] = useState(false) // for refreshing the states
  const [displayedPage, setDisplayedPage] = useState('home');
  const [url, setUrl] = useState("https://facticity-agents-a4xncz5jja-uc.a.run.app")
  const [selectedModel, setSelectedModel] = useState("Agents"); // setting it to 'Agents' so that it will use the else case of the 'selectedModel' useEffect
  const [defaultUrl, setDefaulturl] = useState("https://facticity-backend-a4xncz5jja-as.a.run.app")
  const { user, isAuthenticated } = useAuth0();

  // const [storedIsAuthenticated, setStoredIsAuthenticated] = useState(false);

   // Function to update local storage with Auth0 values
  // const updateLocalStorage = () => {
  //   localStorage.setItem('user', JSON.stringify(user));
  //   localStorage.setItem('isAuthenticated', isAuthenticated.toString());
  // };

  function isLongOrUrl(query) {
    // Check if the length of the query is greater than 250
    if (query.length > 250) {
      return true;
    }
  
    // Regular expression to check if the query is a URL from YouTube or TikTok
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$' + // fragment locator
      '|(https?:\\/\\/)?(www\\.)?(youtube\\.com|youtu\\.be|tiktok\\.com)' + // YouTube and TikTok specific patterns
      '(\\/[-a-z\\d%_.~+@]*)*' + // path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
    );
  
    // Check if the query matches the URL pattern
    if (urlPattern.test(query)) {
      return true;
    }
  }
  // Example usa

  useEffect(() => {
    if (selectedModel === "GPT 4o"){
      //setUrl("http://127.0.0.1:5000")
      setUrl("https://facticity-backend-a4xncz5jja-as.a.run.app")
    }
    else if(selectedModel === "Gemini Pro"){
      setUrl("https://fbgemini-a4xncz5jja-as.a.run.app")
    }else{
      // setUrl("http://127.0.0.1:5000")
      setUrl("https://facticity-agents-a4xncz5jja-uc.a.run.app")
    }
  }, [selectedModel]);


  useEffect(() => {
    console.log(window.location.href);
    setDisplayedPage(window.location.hash.split("#/")[1]); 
  }, []);
  
  useEffect(() => {
    // Step 1: Capture the hash from the current URL
    const hash = window.location.hash;
  
    // Step 2: Check if the hash contains a query string
    if (hash.includes('?')) {
      // Step 3: Extract the query string part from the hash
      const queryString = hash.split('?')[1];
  
      // Step 4: Create a new URLSearchParams object with the query string
      const urlParams = new URLSearchParams(queryString);
  
      // Step 5: Extract the 'url' and 'text' parameters
      const urlToCheck = urlParams.get('url');
      const textToCheck = urlParams.get('text');
  
      // Step 6: Check if the url or text parameter exists and execute a function
      if (urlToCheck || textToCheck) {
        const valueToCheck = urlToCheck || textToCheck;
        setInputText(valueToCheck);
        handleVerifyClick();
      }
    }
  }, [isAuthenticated]); // Dependency array means this effect runs when 'isAuthenticated' changes
  

  // Effect to check local storage on component mount
  // useEffect(() => {
  //   const storedUserData = localStorage.getItem('user');
  //   const storedAuthStatus = localStorage.getItem('isAuthenticated');

  //   if (storedUserData && storedAuthStatus) {
  //     setStoredUser(JSON.parse(storedUserData));
  //     setStoredIsAuthenticated(storedAuthStatus === 'true');
  //   } else if (isAuthenticated && user) {
  //     updateLocalStorage();
  //   }
  // }, []);

  // Effect to update local storage when Auth0 values change
  // useEffect(() => {
  //   if (isAuthenticated && user) {
  //     updateLocalStorage();
  //   }
  // }, [user, isAuthenticated]);


  //const url = "https://facticityupdates-a4xncz5jja-uc.a.run.app"
  // const url = "http://127.0.0.1:5000"
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleLanguageChange = (event) => {
    Setlanguage(event.target.value);
  };

  useEffect(() => {
    if (selectedModel !== "Agents") {
      const latestRequestId = requestId[requestId.length - 1];
      let count = 0;
      let lastMessage = "";
  
      const fetchStatus = async () => {
        try {
          const response = await fetch(url + `/GetProgress/${latestRequestId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data; // assuming the response has a 'status' field
        } catch (error) {
          console.error('Failed to fetch status:', error);
          return null;
        }
      };
  
      if (latestRequestId) {
        // Wait 3 seconds before starting the interval
        const timeoutId = setTimeout(() => {
          const interval = setInterval(async () => {
            try {
              const resultStatus = await fetchStatus();
              console.log(resultStatus);
  
              if (resultStatus && resultStatus.status === 'Completed') {
                notify(resultStatus.message);
                clearInterval(interval);
              } else if (resultStatus && resultStatus.message === 'Task not found') {
                count++;
                if (count === 30) {
                  clearInterval(interval);
                }
              } else {
                if (lastMessage !== resultStatus.message) {
                  notify(resultStatus.message);
                  lastMessage = resultStatus.message;
                }
              }
            } catch (error) {
              console.error('Error during interval execution:', error);
            }
          }, 2000);
        }, 3000); // 3000 milliseconds = 3 seconds
  
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [requestId]);


  const handleVerification = async (query, mode) => {
    try {
      const uniqueId = uuidv4();
      const userEmail = user ? user.email : "NA";
      const timestamp = Date.now();
      const location = position;

      setRequestId(prevIds => [...prevIds, uniqueId]);

      if (mode === "verify") {
        const { output, error, loading } = await createAndCheckTask(query, location, timestamp, userEmail, "speaker", "", "toast");

        if (error) {
          console.error('Error fetching data:', error);
          return;
        }

        console.log({output2:output});
        var tempcount = count;
        if (output.type === 'url') {
          for (let i = 0; i < output.claims.length; i++) {
            const claim = output.claims[i];
            const item = {
              transcript: claim,
              idx: tempcount++,
              color: '#808080',
              verdict: "Processing",
              sources: [],
              ready: false,
              explanation: "",
              evidence: {}
            };

            await new Promise(resolve => setTimeout(resolve, 2000)); // Delay for 1 second
            setTranscripts(prevArray => [...prevArray, item]);
            setOutput(prevArray => [...prevArray, item]);
          }
        }
        setCount(tempcount);
        return output;
      } else {
        // const apiUrl = defaultUrl + '/ProcessEverything';
        // const requestBody = { query: query, location: position, timestamp: timestamp, userEmail: userEmail, requestID: uniqueId };

        // const response = await fetch(apiUrl, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(requestBody),
        // });

        // if (!response.ok) {
        //   throw new Error(`HTTP error! Status: ${response.status}`);
        // }

        // const data = await response.json();
        // console.log(data);
        // var tempcount = count;
        // if (data.type === 'url') {
        //   for (let i = 0; i < data.claims.length; i++) {
        //     const claim = data.claims[i];
        //     const item = {
        //       transcript: claim,
        //       idx: tempcount++,
        //       color: '#808080',
        //       verdict: "Processing",
        //       sources: [],
        //       ready: false,
        //       explanation: "",
        //       evidence: {},
        //       mode : "verify"
        //     };

        //     await new Promise(resolve => setTimeout(resolve, 3000)); // Delay for 1 second
        //     setTranscripts(prevArray => [...prevArray, item]);
        //     setOutput(prevArray => [...prevArray, item]);
        //   }
        // }
        // setCount(tempcount);
        return {};
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const notify = (question) => {
    const notificationMessage =  question;
    const notificationDuration = 30; // Set the duration to 10 seconds
    toast(notificationMessage, {
      draggable:true,
      autoClose:1000
    });
  };
  function countWords(inputString) {
    const trimmedString = inputString.trim();
    if (trimmedString === "") {
      return 0;
    }
    const wordsArray = trimmedString.split(/\s+/);
    return wordsArray.length;
  }
  // const handleVerifyClick = () => {
  //   if (isAuthenticated){
  //     if (inputText != ""){
  //       if (countWords(inputText)>=1){
  //         const transcript = inputText
  //         console.log(count)
  //         var mode = "verify";
  //         if (isLongOrUrl(inputText)) {
  //           mode = "extractFacts";
  //         }
  //         console.log({click:mode})
  //         console.log({ mode });
  //         var input = {transcript:transcript, idx:count, color:'#808080',verdict: "Processing", sources:[], ready:false, explanation: "", evidence:{}, mode:mode}
          
  //         setTranscripts(prevTranscripts => [...prevTranscripts, input]);
  //         setOutput(prevTranscripts => [...prevTranscripts, input]);
  //         setSelectedClaim(count)
  //         setCount(count+1)
  //         setInputText('')
  //         console.log('Verification logic for:', inputText);
  //         setFactCounter(factCounter+1);

  //       }else{
  //         notify("Key in a proper sentence.")
  //       }
  
  //     }
  //   }else{
  //     notify("Please log in")
  //   }
  // };


  const handleVerifyClick = () => {
    if (isAuthenticated) {
      if (inputText !== "") {
        if (countWords(inputText) >= 1) {
          const transcript = inputText.trim();
          console.log(count);
          var mode = "verify";
          if (isLongOrUrl(inputText.trim())) {
            mode = "extractFacts";
          }
          console.log({ click: mode });
          console.log({ mode });
  
          var input = {
            transcript: transcript,
            idx: count,
            color: '#808080',
            verdict: "Processing",
            sources: [],
            ready: false,
            explanation: "",
            evidence: {},
            mode: mode
          };
  
          // If mode is "extractFacts", set the mode of all other "extractFacts" modes to null and add input
          setTranscripts(prevTranscripts => {
            const updatedTranscripts = prevTranscripts.map(transcript => {
              if (transcript.mode === "extractFacts") {
                return { ...transcript, mode: "Skip" };
              }
              return transcript;
            });
            return [...updatedTranscripts, input];
          });

          setOutput(prevOutput => {
            const updatedOutput = prevOutput.map(output => {
              if (output.mode === "extractFacts") {
                return { ...output, mode: "Skip" };
              }
              return output;
            });
            return [...updatedOutput, input];
          });

  
          setSelectedClaim(count);
          setCount(count + 1);
          setInputText('');
          console.log('Verification logic for:', inputText.trim());
          setFactCounter(factCounter + 1);
  
        } else {
          notify("Key in a proper sentence.");
        }
      }
    } else {
      notify("Please log in");
    }
  };
  


  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      // Execute your function here
      handleVerifyClick();
    }
  };

  useEffect(() => {
    // Open the dialog when the component mounts
    setOpen(true);
  }, []);

  //get location
  useEffect(() => {
    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //     setPosition({
    //       latitude: position.coords.latitude,
    //       longitude: position.coords.longitude,
    //     });
    //   });
    // } else {
    //   console.log("Geolocation is not available in your browser.");
    // }
  }, []);

  const handleClose = () => {
    // Close the dialog
    setOpen(false);
  };

  const updateNthElement = (index, newValue) => {
    setOutput(prevArray => {
      // Create a copy of the array
      const newArray = [...prevArray];
      // Update the nth index value
      newArray[index] = newValue;
      // Return the updated array
      return newArray;
    });
  };

  const updateNthElementForDoc = (index, newValue) => {
    setDocOutput(prevArray => {
      // Create a copy of the array
      const newArray = [...prevArray];
      // Update the nth index value
      newArray[index] = newValue;
      // Return the updated array
      return newArray;
    });
  };
  const printObj = (obj) => {
    console.log("loop printing")
    for (const key in obj) {
      console.log(key + ':', obj[key]);
    }
  }
  const generateRandomNumber = () => {
    const min = 1;
    const max = 3;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber
  };

  const handleMicToggle = () => {
    // Toggle the state of micEnabled
    setIsListening((isListening) => !isListening);

  };


  useEffect(() => {
    if (isListening) {
      const recognition = new speechRecognition();
      recognition.continuous = true;
      recognition.lang = language;
      recognition.interimResults = false;
      recognition.start();

      recognition.onresult = (event) => {
        console.log(event.results[[event.results.length - 1]])
        console.log(event.results[[event.results.length - 1]][0].transcript)

        const transcript = event.results[[event.results.length - 1]][0].transcript

        var input = {transcript:transcript, idx:count, color:'#eeeeee',verdict: "True", sources:[], ready:false, explanation: ""}
        setTranscripts(prevTranscripts => [...prevTranscripts, input]);
        setOutput(prevTranscripts => [...prevTranscripts, input]);
        setSelectedClaim(count)
        setCount(count+1)
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event);
      };

      return () => recognition.stop();
    }
  }, [isListening, count, language]);

  useEffect(() => {
    const fetchData = async () => {
      // await new Promise(resolve => setTimeout(resolve, 5000));
      const lastTranscript = transcripts[transcripts.length - 1];
      // here remove the extra tokens from lastTranscript
      const fetchedData = await handleVerification(lastTranscript.transcript, lastTranscript.mode);
      console.log(fetchedData)
      var number = generateRandomNumber();
      lastTranscript.id = fetchedData.id
      //console.log("lasttranscript: " + lastTranscript.transcript + "transid:" + lastTranscript.id)
      if (fetchedData.status === "ok"){
        if (fetchedData.Classification === 'False'){
          lastTranscript.verdict = 'False'
          lastTranscript.ready = true
          lastTranscript.sources = fetchedData.sources
          lastTranscript.disambiguation = fetchedData.disambiguation
          lastTranscript.color = "#FFDBDB"
          lastTranscript.explanation = fetchedData.overall_assessment
          lastTranscript.evidence = fetchedData.evidence
          lastTranscript.bias = fetchedData.bias
          // notify(fetchedData.question)
        }
        else if (fetchedData.Classification === 'True'){
          lastTranscript.verdict = 'True'
          lastTranscript.ready = true
          lastTranscript.disambiguation = ''
          lastTranscript.sources = fetchedData.sources
          lastTranscript.color = "#B9FFC8"
          lastTranscript.disambiguation = fetchedData.disambiguation
          lastTranscript.explanation = fetchedData.overall_assessment
          lastTranscript.evidence = fetchedData.evidence
          lastTranscript.bias = fetchedData.bias
        }
        else if (fetchedData.Classification === 'Inconclusive'){
          lastTranscript.verdict = 'Unverifiable'
          lastTranscript.ready = true
          lastTranscript.sources = fetchedData.sources
          lastTranscript.disambiguation = fetchedData.disambiguation
          lastTranscript.color = "#FFFDD0"
          lastTranscript.explanation = fetchedData.overall_assessment
          lastTranscript.evidence = fetchedData.evidence
          lastTranscript.bias = fetchedData.bias
          // notify(fetchedData.question)
        }
        else{
          lastTranscript.verdict = 'Unverifiable'
          lastTranscript.ready = true
          lastTranscript.sources = fetchedData.sources
          lastTranscript.disambiguation = fetchedData.disambiguation
          lastTranscript.color = "#FFFDD0"
          lastTranscript.explanation = fetchedData.overall_assessment
          lastTranscript.evidence = fetchedData.evidence
          lastTranscript.bias = fetchedData.bias
        }
      } else {
        lastTranscript.verdict = ''
        lastTranscript.ready = true
        lastTranscript.sources = []
        lastTranscript.color = "#FFFDD0"
        lastTranscript.explanation = fetchedData.error
        lastTranscript.evidence = {}
        setInputText(fetchedData.ammended)
        
        
      }
      
      updateNthElement(lastTranscript.idx, lastTranscript)
    };
    if (transcripts.length>0){
      fetchData();
    }
    
  }, [transcripts]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // await new Promise(resolve => setTimeout(resolve, 5000));
  //     const lastTranscript = docTranscripts[docTranscripts.length - 1];
  //     //console.log("dt inside ue: " + docTranscripts)
  //     // here remove the extra tokens from lastTranscript
  //     const fetchedData = await handleVerification(lastTranscript.transcript);
  //     console.log(fetchedData)
  //     var number = generateRandomNumber();
  //     lastTranscript.id = fetchedData.id
  //     //console.log("lasttranscript: " + lastTranscript.transcript + "transid:" + lastTranscript.id)
  //     if (fetchedData.status === "ok"){
  //       if (fetchedData.Classification === 'False'){
  //         lastTranscript.verdict = 'False'
  //         lastTranscript.ready = true
  //         lastTranscript.sources = fetchedData.sources
  //         lastTranscript.disambiguation = fetchedData.disambiguation
  //         lastTranscript.color = "#FFDBDB"
  //         lastTranscript.explanation = fetchedData.overall_assessment
  //         lastTranscript.evidence = fetchedData.evidence
  //         // notify(fetchedData.question)
  //       }
  //       else if (fetchedData.Classification === 'True'){
  //         lastTranscript.verdict = 'True'
  //         lastTranscript.ready = true
  //         lastTranscript.sources = fetchedData.sources
  //         lastTranscript.disambiguation = ''
  //         lastTranscript.color = "#B9FFC8"
  //         lastTranscript.explanation = fetchedData.overall_assessment
  //         lastTranscript.evidence = fetchedData.evidence
  //       }
  //       else if (fetchedData.Classification === 'Inconclusive'){
  //         lastTranscript.verdict = 'Unverifiable'
  //         lastTranscript.ready = true
  //         lastTranscript.sources = fetchedData.sources
  //         lastTranscript.disambiguation = fetchedData.disambiguation
  //         lastTranscript.color = "#FFFDD0"
  //         lastTranscript.explanation = fetchedData.overall_assessment
  //         lastTranscript.evidence = fetchedData.evidence
  //         // notify(fetchedData.question)
  //       }
  //       else{
  //         lastTranscript.verdict = 'Unverifiable'
  //         lastTranscript.ready = true
  //         lastTranscript.sources = fetchedData.sources
  //         lastTranscript.disambiguation = fetchedData.disambiguation
  //         lastTranscript.color = "#FFFDD0"
  //         lastTranscript.explanation = fetchedData.overall_assessment
  //         lastTranscript.evidence = fetchedData.evidence
  //       }
  //     } else {
  //       lastTranscript.verdict = ''
  //       lastTranscript.ready = true
  //       lastTranscript.sources = []
  //       lastTranscript.color = "#FFFDD0"
  //       lastTranscript.explanation = fetchedData.error
  //       lastTranscript.evidence = {}
  //       setInputText(fetchedData.ammended)
        
  //     }
      
  //     updateNthElementForDoc(lastTranscript.idx, lastTranscript)
  //   };
  //   if (docTranscripts.length>0){
  //     fetchData();
  //   }
    
  // }, [docTranscripts]); //uploadedDocs

  const toggleListening = () => {
    // setIsListening(!isListening);
    handleClose();
  };

  const handleCopyEvent = (text) =>{
    navigator.clipboard.writeText(text); 
  }

  const getCurrDocTranscripts = (doc) => {
    const transcriptsOfCurrDoc = doc.docTranscriptsIndices.map(index => docTranscripts[index]).filter(content => content !== undefined);
    //console.log("in getcurr" + transcriptsOfCurrDoc)
    return transcriptsOfCurrDoc;
  }

  const style = {
    position: 'fixed',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    paddingBottom: '10px', // Adjust as needed
    backgroundColor: '#F1E7FF', // Add this line for white background
  };

  const bottomToolBarStyle = {    
  display: 'flex', // Set display to flex
  alignItems: 'center', // Align items vertically in the center
  }

  const style_content = {
    position: 'fixed',
    alignItems: 'center',
    left: 0,
    right: 0,
    paddingTop: '40px',
  };

  // from sincerely -- start -- 
  const [assignmentFile, setAssignmentFile] = useState(null); //useState(null);
  const [assignmentText, setAssignmentText] = useState(null);
  const [src, setSrc] = useState(null);
  const [paragraphs, setParagraphs] = useState(null);
  let fileReader;
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const isPdf = (filename) => {
    return (filename.split('.').pop()).toLowerCase() == "pdf";
  }

  const isDocx = (filename) => {
    return (filename.split('.').pop()).toLowerCase() == "docx";
  }

  // extract text from a parseable pdf file
  const pdfToText = (data) => {
    console.assert( data  instanceof ArrayBuffer  || typeof data == 'string' );
    pdfjsLib.getDocument( data ).promise.then( function(pdf) {
      let complete = 0;
      var total = pdf.numPages;    
      var layers = {};        
      for (let i = 1; i <= total; i++){
        pdf.getPage(i).then( function(page){
        var n = page.pageNumber;
        page.getTextContent().then( function(textContent){
          console.log(textContent);
          if( null != textContent.items ){
            var page_text = "";
            var last_block = null;
            for( var k = 0; k < textContent.items.length; k++ ){
                var block = textContent.items[k];
                if( last_block != null && last_block.str[last_block.str.length-1] != ' '){
                    if( block.x < last_block.x )
                        page_text += "\r\n"; 
                    else if ( last_block.y != block.y && ( last_block.str.match(/^(\s?[a-zA-Z])$|^(.+\s[a-zA-Z])$/) == null ))
                        page_text += ' ';
                }
                page_text += block.str;
                last_block = block;
            }
            console.log(page_text);
            // textContent != null && console.log("page " + n + " finished."); //" content: \n" + page_text);
            console.log(textContent);
            layers[n] =  page_text + "\n\n";
          }
          complete++;
           if (complete == total){
            var full_text = "";
            var num_pages = Object.keys(layers).length;
            for( var j = 1; j <= num_pages; j++) {
              full_text += layers[j] ;
            }
            console.log(full_text);
            setAssignmentText(full_text);
           }
        }); // end  of page.getTextContent().then
        }); // end of page.then
      } // of for
    });
  }; // end of pdfToText()

  const handleFileRead = (e) => {
    const content = fileReader.result;
    setParagraphs(content);
    setAssignmentText(content);
  }
  const handlePDFFileRead = (e) => {
    const content = fileReader.result;
    pdfToText(content);
  }

  const isGroupingTokenPresent = (transcript) => {
    return false
  }

  function str2xml(str) {
    if (str.charCodeAt(0) === 65279) {
      // BOM sequence
      str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
  }

  function getParagraphs(content) {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const paragraphsXml = xml.getElementsByTagName("w:p");
    const paragraphs = [];
  
    for (let i = 0, len = paragraphsXml.length; i < len; i++) {
      let fullText = "";
      const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
      for (let j = 0, len2 = textsXml.length; j < len2; j++) {
        const textXml = textsXml[j];
        if (textXml.childNodes) {
          fullText += textXml.childNodes[0].nodeValue;
        }
      }
      if (fullText) {
        paragraphs.push(fullText);
      }
    }
    return paragraphs;
  }

  const handleAssignmentUpload = (event) => {
    if (isAuthenticated){
      const file = event.target.files[0];
      // File size shouldn't be more than 5mb
      if (file.size > 5000000) {
        toast.error("File size too large. Please upload a file smaller than 5 mb");
        event.target.value = null;
        return;
      }
      setAssignmentFile(file);
      
      if (isPdf(file.name)) { // handle separately if pdf file as extra processing required
        setSrc(URL.createObjectURL(event.target.files[0]));
        fileReader = new FileReader();
        fileReader.onloadend = handlePDFFileRead;
        fileReader.readAsArrayBuffer(file);
      } else if (isDocx(file.name)) {
        fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          const content = e.target.result;
          const paragraphs = getParagraphs(content);
          setParagraphs(paragraphs.join('\n'));
          setAssignmentText(paragraphs.join('\n'));
        };
        fileReader.onerror = (err) => console.error(err);
        fileReader.readAsBinaryString(file);
  
  
      } else {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
      }
      console.log("text in the file is: ", assignmentText)  
    } else {
      notify("Please log in")
    }
    
  };

  // from sincerely -- end


  const handleDocReportDownload = (docKey, docName) => {
    const wb = XLSX.utils.book_new();

    // Create a worksheet for the data
    var currTranscripts = []
    for(const tran of docTranscripts){
        if(tran.docKey == docKey){
          currTranscripts.push(tran)
        }
    }
    const wsData = XLSX.utils.json_to_sheet(currTranscripts.map(doc => ({
        'Claim/Fact': doc.transcript,
        'Classification': doc.verdict,
        'Assessment': doc.explanation,
        'Disambiguation': doc.disambiguation,
        'Sources':doc.sources.map(item => item.sources.join("\n")).join("\n\n")
    })));

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, wsData, 'Data');
    var docTotal = currTranscripts.length
    var docTrue = getDocTrueCount(docKey)
    var docFalse = getDocFalseCount(docKey)
    var docUnverifiable = getDocUnverifiableCount(docKey)


    // Additional information
    const wsInfo = XLSX.utils.aoa_to_sheet([
        ['Document Name', 'Total Facts', 'True', 'False', 'Unverifiable'],
        [docName, docTotal, docTrue, docFalse, docUnverifiable]
    ]);

    // Add the additional information to the workbook
    XLSX.utils.book_append_sheet(wb, wsInfo, 'Info');

    // Generate a file name
    const fileName = 'facticity' + '_' + docName + '.xlsx';

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  }

  const getDocTrueCount = (docKey) => {
    var trueCount = 0
    for(const transcript of docTranscripts)
      {
        if(transcript.docKey == docKey && transcript.verdict == "True")
          {
            trueCount += 1
          }
      }
      return trueCount
  }

  const getDocFalseCount = (docKey) => {
    var trueCount = 0
    for(const transcript of docTranscripts)
      {
        if(transcript.docKey == docKey && transcript.verdict == "False")
          {
            trueCount += 1
          }
      }
      return trueCount
  }
  const getDocUnverifiableCount = (docKey) => {
    var trueCount = 0
    for(const transcript of docTranscripts)
      {
        if(transcript.docKey == docKey && transcript.verdict == "Unverifiable")
          {
            trueCount += 1
          }
      }
      return trueCount
  }

  const getDocQueueCount = (docKey) => {
    var trueCount = 0
    for(const transcript of docTranscripts)
      {
        if(transcript.docKey == docKey && transcript.isQueuedNow == true)
          {
            trueCount += 1
          }
      }
      return trueCount
  }


  const callVerifyClickForClaimsSecond = async (factual_claim, personal_claims, currDocument) => {
    var tempFactualClaimCount = 0
    for (const claim of factual_claim) {
      const transcript = claim
      const input = {docKey: currDocument.key, transcript:transcript, idx:docFactCount, color:'#808080',verdict: "Processing", sources:[], ready:false, explanation: "", evidence:{}, isPickedNow: false, isQueuedNow: true}
      setDocTranscripts(prevTranscripts => [...prevTranscripts, input])
      //console.log(docTranscripts)
      setDocFactCount(prevCount => prevCount + 1)
      setInputText('')
      currDocument.docTranscriptsIndices.push(input.idx)
      currDocument.docTranscripts.push(input)
      setFactCounter(factCounter+1);
      tempFactualClaimCount += 1
    }
    const allDocsDict = { ...uploadedDocs };
    allDocsDict[currDocument.key] = currDocument
    //console.log("allDocs" + allDocsDict)
    setUploadedDocs(allDocsDict, () => {
      //console.log("uploaded docs: " + uploadedDocs);
    });   
    
    var tempCount = 0
    for(const claim of factual_claim) {
    //factual_claim.forEach((claim, index) => {
      //if(countWords(claim) >= 3){
        //if(factCounter < factlimit){
          const transcript = claim
          const input = {docKey: currDocument.key, transcript:transcript, idx:docFactCount, color:'#808080',verdict: "Processing", sources:[], ready:false, explanation: "", evidence:{}}
          //setDocTranscripts(prevTranscripts => [...prevTranscripts, input])
          //setDocFactCount(prevCount => prevCount + 1)
          //setInputText('')
          //currDocument.docTranscriptsIndices.push(input.idx)
          //setFactCounter(factCounter+1);
          const lastTranscript = currDocument.docTranscripts[tempCount]
          console.log("in second loop after tempCount increase")
          lastTranscript.isPickedNow = true
          lastTranscript.isQueuedNow = false
          updateNthElementForDoc(lastTranscript.idx, lastTranscript)
          const fetchedData = await handleVerification(lastTranscript.transcript, 'verify');

          console.log(fetchedData)
          var number = generateRandomNumber();
          lastTranscript.id = fetchedData.id
          if (fetchedData.status === "ok"){
            if (fetchedData.Classification === 'False'){
              lastTranscript.verdict = 'False'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = fetchedData.disambiguation
              lastTranscript.color = "#FFDBDB"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
            else if (fetchedData.Classification === 'True'){
              lastTranscript.verdict = 'True'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = ''
              lastTranscript.color = "#B9FFC8"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
            else if (fetchedData.Classification === 'Inconclusive'){
              lastTranscript.verdict = 'Unverifiable'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = fetchedData.disambiguation
              lastTranscript.color = "#FFFDD0"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
            else{
              lastTranscript.verdict = 'Unverifiable'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = fetchedData.disambiguation
              lastTranscript.color = "#FFFDD0"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
          } else {
            lastTranscript.verdict = ''
            lastTranscript.ready = true
            lastTranscript.sources = []
            lastTranscript.color = "#FFFDD0"
            lastTranscript.explanation = fetchedData.error
            lastTranscript.evidence = {}
            setInputText(fetchedData.ammended)
            
          }

          lastTranscript.isPickedNow = false
          lastTranscript.isQueuedNow = false
          
          updateNthElementForDoc(lastTranscript.idx, lastTranscript)
          tempCount += 1

        //} // for forEach and if
      //}
  }

     
  //) uncomment once you revert to forEach
  }




  //also add fact index number and display in the frontend
  const callVerifyClickForClaimsNew = async (factual_claim, personal_claims, currDocument) => {

    for(const claim of factual_claim) {
    //factual_claim.forEach((claim, index) => {
      if(countWords(claim) >= 3){
        if(factCounter < factlimit){
          const transcript = claim
          var input = {docKey: currDocument.key, transcript:transcript, idx:docFactCount, color:'#808080',verdict: "Processing", sources:[], ready:false, explanation: "", evidence:{}}
          setDocTranscripts(prevTranscripts => [...prevTranscripts, input])
          setDocFactCount(prevCount => prevCount + 1)
          setInputText('')
          currDocument.docTranscriptsIndices.push(input.idx)
          setFactCounter(factCounter+1);
          const lastTranscript = input

          const fetchedData = await handleVerification(lastTranscript.transcript, 'verify');
          //const fetchedData = handleVerification(lastTranscript.transcript);

          console.log(fetchedData)
          var number = generateRandomNumber();
          lastTranscript.id = fetchedData.id
          if (fetchedData.status === "ok"){
            if (fetchedData.Classification === 'False'){
              lastTranscript.verdict = 'False'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = fetchedData.disambiguation
              lastTranscript.color = "#FFDBDB"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
            else if (fetchedData.Classification === 'True'){
              lastTranscript.verdict = 'True'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = ''
              lastTranscript.color = "#B9FFC8"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
            else if (fetchedData.Classification === 'Inconclusive'){
              lastTranscript.verdict = 'Unverifiable'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = fetchedData.disambiguation
              lastTranscript.color = "#FFFDD0"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
            else{
              lastTranscript.verdict = 'Unverifiable'
              lastTranscript.ready = true
              lastTranscript.sources = fetchedData.sources
              lastTranscript.disambiguation = fetchedData.disambiguation
              lastTranscript.color = "#FFFDD0"
              lastTranscript.explanation = fetchedData.overall_assessment
              lastTranscript.evidence = fetchedData.evidence
            }
          } else {
            lastTranscript.verdict = ''
            lastTranscript.ready = true
            lastTranscript.sources = []
            lastTranscript.color = "#FFFDD0"
            lastTranscript.explanation = fetchedData.error
            lastTranscript.evidence = {}
            setInputText(fetchedData.ammended)
            
          }
          
          updateNthElementForDoc(lastTranscript.idx, lastTranscript)

        }
      }
  }

  const allDocsDict = { ...uploadedDocs };
  allDocsDict[currDocument.key] = currDocument
  //console.log("allDocs" + allDocsDict)
  setUploadedDocs(allDocsDict, () => {
    //console.log("uploaded docs: " + uploadedDocs);
   });      
  //) uncomment once you revert to forEach
  }

  const callVerifyClickForClaims = (factual_claims, personal_claims, currDocument) => {
    console.log("in callVerifyClickForClaims")
    let delay = 500; // one-second delay in milliseconds
    //generate 5 digit random number
    factual_claims.forEach((claim, index) => {
        setTimeout(() => {
            //setInputText(claim);
            if (countWords(claim)>=3){
              if (factCounter<2000){
                const transcript = claim
                //console.log(count)
                var input = {docKey: currDocument.key, transcript:transcript, idx:docFactCount, color:'#808080',verdict: "Processing", sources:[], ready:false, explanation: "", evidence:{}}
                //console.log('input: ' + input)
                setDocTranscripts(prevTranscripts => [...prevTranscripts, input])
                //console.log("dt" + docTranscripts)

                setDocFactCount(prevCount => prevCount + 1)
                //console.log('docfact count ' + docFactCount)
                setInputText('')
                currDocument.docTranscriptsIndices.push(input.idx)
                //console.log('Verification logic for:', claim);
                setFactCounter(factCounter+1);
            }else{
              notify("You've reached your session usage limit. Refresh page for more.")
            }
            }else{
              notify("Key in a proper sentence.")  // to check later: if a doc trans is less than 3 words, then what happens when rendering cards
            }
        }, index * delay);
      });

      //const transcript = personal_claims

      personal_claims.forEach((claim, index) => {
        setTimeout(() => {
            //setInputText(claim);
            if (countWords(claim)>=3){
              if (factCounter<factlimit){
                //claim = "<STARTGROUP>" + randToken + '-' + "PDFNAME" + "</ENDGROUP>" +  claim
                const transcript = claim
                //console.log(count)
                var input = {transcript:transcript, idx:count, color:'#808080',verdict: "Processing", sources:[], ready:false, explanation: "", evidence:{}}
                setDocTranscripts(prevTranscripts => [...prevTranscripts, input])

                setDocFactCount(prevCount => prevCount + 1)
                setInputText('')
                currDocument.docTranscriptsIndices.push(input.idx)
                //console.log('Verification logic for:', claim);
                setFactCounter(factCounter+1);
            }else{
              notify("You've reached your session usage limit. Refresh page for more.")
            }
            }else{
              notify("Key in a proper sentence.") // to check later: if a doc trans is less than 3 words, then what happens when rendering cards
            }
        }, index * delay);
      });
      //console.log("doc indices while pushing:" + currDocument.docTranscriptsIndices)
      const allDocsDict = { ...uploadedDocs };
      allDocsDict[currDocument.key] = currDocument
      //console.log("allDocs" + allDocsDict)
      setUploadedDocs(allDocsDict, () => {
        //console.log("uploaded docs: " + uploadedDocs);
       });      

  };

  
  const handleFileSubmit = (documentFile) => { // send processed file text to backend
    // Check if a file is selected
    if (isAuthenticated){
      //if selectedFile
      if(assignmentText == null || assignmentText == '' || assignmentFile == null)
      {notify("The uploaded file is empty.")}
      else{
      let documentName = ''
      if (documentFile !=null && documentFile.name != null){
         documentName = documentFile.name
      }
      else {
         documentName = ''
      }
      setFileProcessLoad(true)
      // const processFileEndpoint = 'http://127.0.0.1:5000/ProcessFileUpload';
      const processFileEndpoint = defaultUrl+'/ProcessFileUpload';
      const isResume = document.getElementById('resume_box').checked
      console.log('check status: '+ isResume)
      console.log('check status type: ' + typeof(isResume))
      // const apiUrl = 'https://sincerely-fact-check-a4xncz5jja-as.a.run.app/ProcessFileUpload'
      if (assignmentText) {
        // Create a FormData object
        const formData = new FormData();
        // Append the file to the FormData object
        formData.append('fileData', assignmentText);
        isResume? formData.append('isResume', 'true') : formData.append('isResume', 'false')
        //console.log("file upload event:", formData)
        const randToken = Math.floor(Math.random() * 90000) + 10000
        setUploadedDocCount(uploadedDocCount+1)
        const docKey = "randToken" + randToken + "docIndex" + uploadedDocCount
        const currDocument = {key: docKey, randToken : randToken , index: uploadedDocCount, name: documentName, docTranscriptsIndices: [], docTranscripts: []}
        const allDocsLoadDict = docsProcessLoad
        allDocsLoadDict[docKey] = true //doc is loading, facts are being extracted
        setDocsProcessLoad(allDocsLoadDict)
        const allDocsDict = uploadedDocs
        allDocsDict[docKey] = currDocument
        //console.log("alldocdict[key]" + allDocsDict[docKey])
        setUploadedDocs(allDocsDict)
        //console.log("up doc: " + uploadedDocs)
        // Make a POST request to send the FormData object to the backend
        fetch(processFileEndpoint, {
          method: 'POST',
          body: formData
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Extract JSON from the response
        })
        .then(data => {
          //handleFileModalClose() //close the file modal after clicking verify
          setFileProcessLoad(false)
          const allDocsLoadDict = docsProcessLoad
          //console.log("doc key before setting false: ", docKey)
          allDocsLoadDict[docKey] = false //doc is loading, facts are being extracted
          setDocsProcessLoad(allDocsLoadDict)
          console.log("file upload response received") // this now has the claims extracted from the document/resume
          console.log(data); // Log the response data
          // Handle the data as needed
          //callVerifyClickForClaims(data['factual_claim'], data['personal_claim'], currDocument)
          //callVerifyClickForClaimsNew(data['factual_claim'], data['personal_claim'], currDocument)
          callVerifyClickForClaimsSecond(data['factual_claim'], data['personal_claim'], currDocument)

          //setSingleTableClaims(data['factual_claim'], data['personal_claim'], currDocument)
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          // Handle error
        });
      }
      } 
    } else{
      notify("Please log in")
    }
    
  };

  const handleBackButton = () => {  // to clear states when file-upload modal is closed.
    //console.log("in handle back. before if. assgnt file is: ", assignmentFile)
    if (assignmentFile != null) {
      //console.log("inside assgn != null check")
      setAssignmentFile(null);
      setParagraphs(null);
      setAssignmentText(null);
      setParagraphs(null);
    }
  }

  const [fileModalOpen, setFileModalOpen] = useState(false);

  const handleFileModalOpen = () => {
    setFileModalOpen(true);
  };

  const handleFileModalClose = () => {
    //console.log('handle modal close is invoked')
    setFileModalOpen(false);
    setAssignmentFile(null) //new
    setAssignmentText(null)
    setParagraphs(null)
    handleBackButton()
    setManualReload(!manualReload)
    //console.log('assgn file is: ' + assignmentFile)
  };




  useEffect(() => {
    const fetchVideos = async () => {
      try {
        console.log(defaultUrl+'/get_example_videos')
        const response = await fetch(defaultUrl+'/get_example_videos');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVideos(data.videos);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  const getRowBackgroundColor = (verdict) => {
    switch (verdict) {
      case "False":
        return 'rgba(255, 0, 0, 0.2)'; // Translucent red
      case "True":
        return 'rgba(0, 255, 0, 0.2)'; // Translucent green
      case "Unverifiable":
        return 'rgba(255, 255, 0, 0.2)'; // Translucent yellow
        case "Inconclusive":
        return 'rgba(255, 255, 0, 0.2)'; // Translucent yellow
      default:
        return 'transparent';
    }
  };

  const getRowBadgeColor = (classification) => {
    switch (classification) {
      case 'True':
        return 'success';
      case 'Processing':
        return 'warning';
      case 'False':
        return 'error';
      case 'Inconclusive':
        return 'warning';
      case 'Unverifiable':
        return 'warning';
      case '':
        return 'info';
      default:
        return 'info'; // Default to error color for unknown classifications
    }
  };


//start of html
  return (
    <div style = {{fontFamily : 'IBM Plex Sans'}}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="fixed"
            style={{
              width: '100%',
              backgroundColor: '#0A0A0A',
              //backgroundImage: 'linear-gradient(to right, #8E2DE2 ,#8E2DE2 )',
              boxShadow: 'none',
            }}
          >
            <Toolbar variant="dense">
              <Grid container>
                <Grid item md={1} xs={0} variant="outlined"></Grid>
                <Grid item md={10} xs={12} variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="h4"
                    color="white"
                    component="div"
                    onClick={() => {
                      setDisplayedPage('home');
                    }}
                    sx={{ flexGrow: 1 }}
                  >
                    Facticity
                  </Typography>
                  {!isAuthenticated && <LoginButton notify={notify} setDisplayedPage={setDisplayedPage} />}
                  <AccountProfile setDisplayedPage={setDisplayedPage} selectedModel = {selectedModel} setSelectedModel={setSelectedModel}/>
                </Grid>
                <Grid item md={1} xs={0} variant="outlined"></Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <ToastContainer />
        </Box>
        <div>
          {displayedPage === 'profile' ? (<ProfilePage user = {user} setDisplayedPage={setDisplayedPage}></ProfilePage>) : 
          displayedPage === 'signup' ? (<SignupForm setDisplayedPage={setDisplayedPage}/>) : 
          displayedPage === 'email-verification' ? (<EmailConfirmation/>) : 
          displayedPage === 'pricing' ?(<PricingPage setDisplayedPage={setDisplayedPage}/>):(
        <div>
        <Container style = {{marginBottom: '20px'}}>
          <div>
          {!isAuthenticated && <LoginModal open = {true} notify={notify} setDisplayedPage={setDisplayedPage} />}
          </div>
        {transcripts.length === 0 && isListening === false &&(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div style={{ textAlign: 'center' }}>
                  <motion.div
                          initial={{ opacity: 0, y: -50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 50 }}
                        >
                  <Typography sx={{ fontSize: 40 }}>
                    Type a <span style={{ color: '#7407FF' }}>fact</span> about <br /> anything or anyone
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    and I'll fact check it!
                  </Typography>
                    <Card style={{boxShadow: 'none'}}>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', paddingLeft: '10px' }}>
                        Some <span style={{ color: '#7407FF' }}>examples</span>
                      </Typography>
                      <Typography sx={{ fontSize: 12, textAlign: 'left', paddingLeft: '20px' }}>
                        <SearchIcon sx={{ fontSize: 13 }} /> Mount Everest has an elevation of 8,848.86 meters.
                      </Typography>
                      <Typography sx={{ fontSize: 12, textAlign: 'left', paddingLeft: '20px' }}>
                        <SearchIcon sx={{ fontSize: 13 }} /> The Earth is round.
                      </Typography>
                      <Typography sx={{ fontSize: 12, textAlign: 'left', paddingLeft: '20px' }}>
                        <SearchIcon sx={{ fontSize: 13 }} /> Paste a Youtube link or Upload a file.
                      </Typography>
                      <VideoCarousel videos={videos} verify={handleVerifyClick} setInputText={setInputText}/>
                    </Card>
                  </motion.div>
                </div>
              </div>
              
        )}
        <div style={style_content}>
          <Grid container spacing={1}>
              {/* Left Spacer */}
              <Grid item md={1} xs ={0} variant="outlined"></Grid>

              {/* Middle Content */}
              <Grid item md={10}  xs ={12} style={{ 
                        height: '100vh', 
                        overflow: 'hidden', 
                        overflowY: 'scroll', 
                        msOverflowStyle: 'none',  /* IE and Edge */
                        scrollbarWidth: 'none'  /* Firefox */
                    }}>
              {/* <HideScrollbar /> */}
              {/* old cards start*/}
              <div>
              {[...output].reverse().map((transcript, index) => (

                <div>
                  <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    style={{position:"relative"}}
                    onMouseOver={e => {
                      setHoveredId(transcript.id);
                      // setCopyStyle({position:"absolute",right:"0",top:"10px",display: 'flex', id: transcript.id});
                    }}
                    onMouseLeave={e => {
                      setHoveredId(null);
                      // setCopyStyle({display: 'none'})
                    }}
                  >
                  {hoveredId === transcript.id && transcript.mode === 'verify' &&(
                    <Fab 
                      variant="extended" 
                      size="small" 
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "10px",
                        display: 'flex'
                      }} 
                      onClick={() => {handleCopyEvent(transcript.transcript)}}
                    >
                      <ContentCopyIcon sx={{ mr: 1, fontSize: "small" }} />
                      {copyText}
                    </Fab>
                  )}


                  {transcript.mode === 'verify' ? (
                    <ExpandComponent 
                      id={transcript.id} 
                      claim={transcript.transcript} 
                      assessment={transcript.explanation} 
                      color={transcript.color} 
                      classification={transcript.verdict} 
                      sources={transcript.sources} 
                      ready={transcript.ready} 
                      evidence={transcript.evidence} 
                      disambiguation={transcript.disambiguation} 
                      bias={transcript.bias}
                    />
                  ) : transcript.mode === 'extractFacts' ? (
                    <VideoParagraphComponent
                      key={index}
                      id={transcript.id} 
                      claim={transcript.transcript} 
                      email={user.email}
                      readyin = {false}
                    />
                  ) : (
                    <SimpleClaimComponent
                    claim={transcript.transcript} 
                    >
                    </SimpleClaimComponent>
                  )}


                  </motion.div>
                </div>
 
                ))}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              {/* <br></br>
              <br></br>
              <br></br> */}
                {/* ))}  */}
              </div>
              {/* old cards end*/}
          
              </Grid>

              {/* Right Spacer */}
              <Grid item md={1} xs={0}></Grid>
          </Grid>
        </div>  
        </Container>
        <div style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#0A0A0A',
          //backgroundImage: 'linear-gradient(to right, #8E2DE2 ,#8E2DE2 )',
          color: '#fff',
          textAlign: 'center',
          paddingBottom: '20px',
          paddingTop: '20px',
        }}>
              <Grid item xs={0} md={1}>
              </Grid>
          <    Grid item xs={12} md={10} style = {{textAlign : 'center'}}>
                <CustomTextField
                  style={{ width: '98%', background: 'white', backgroundColor: 'white',  borderRadius: '20px', textAlign: 'center'}}
                  value={inputText}
                  placeholder="Upload file, Enter a fact or video link here" 
                  // label="Facticity" 
                  variant="outlined" 
                  multiline
                  maxRows={5}  
                  onKeyDown={handleEnter}
                  onChange={(e) => setInputText(e.target.value)}
                
                  InputProps={{sx: { borderRadius: 5 }, endAdornment: <Button onClick={handleVerifyClick}
                                                        style={{ 
                                                          fontSize: 16,
                                                          fontFamily: 'IBM Plex Sans', 
                                                          borderRadius: '20px', 
                                                          backgroundColor: '#0A0A0A', 
                                                          color: 'white', 
                                                          padding: '8px 15px', 
                                                          cursor: 'pointer', 
                                                          marginRight: '15px',
                                                          whiteSpace: 'nowrap', 
                                                          textOverflow: 'ellipsis', 
                                                        }}
                                                      >Verify</Button>, 
                                                      startAdornment: (
                                                        <div>
                                                          <div>
                                                        
                                                                  {/* <UploadIcon onClick = {handleFileModalOpen} color = "white" style={{ fontSize: 40, borderRadius: '20px', backgroundColor: 'rgb(116, 7, 255)', color: 'white', padding: '10px', cursor: 'pointer', marginRight: '15px' }}>Upload Doc</UploadIcon> */}
                                                                  <Button 
                                                                      onClick={handleFileModalOpen} 
                                                                      variant="contained" 
                                                                      // startIcon={<UploadIcon />} 
                                                                      style={{ 
                                                                        fontSize: 14, 
                                                                        fontFamily: 'IBM Plex Sans', 
                                                                        borderRadius: '20px', 
                                                                        backgroundColor: '#0A0A0A', 
                                                                        color: 'white', 
                                                                        padding: '6px 12px', 
                                                                        cursor: 'pointer', 
                                                                        marginRight: '15px',
                                                                        whiteSpace: 'nowrap', 
                                                                        textOverflow: 'ellipsis', 
                                                                      }}
                                                                    >
                                                                      {/* Upload */}
                                                                      <UploadIcon></UploadIcon>
                                                                    </Button>
                                                              {/* </label> */}
                                                          </div>
                                                          <div>
                                                            {/* <Button onClick={handleFileModalOpen}>Open Modal</Button> */}
                                                            
                                                            <Modal
                                                              open={fileModalOpen}
                                                              style = {{overflowY: 'auto', maxHeight: '80%'}}
                                                              onClose={handleFileModalClose}
                                                              aria-labelledby="modal-title"
                                                              aria-describedby="modal-description"
                                                              closeAfterTransition
                                                              //BackdropComponent={Backdrop}
                                                              BackdropProps={{
                                                                //timeout: 500,
                                                              }}
                                                            >
                                                              <Fade in={fileModalOpen}>
                                                                <div
                                                                  style={{
                                                                    backgroundColor: 'white',
                                                                    border: '2px solid black',
                                                                    padding: '20px',
                                                                    //maxWidth: '400px',
                                                                    width: '80%',
                                                                    margin: 'auto',
                                                                    marginTop: '100px',
                                                                    borderRadius: '10px',
                                                                  }}
                                                                >
                                                                  <h2 id="modal-title" style = {{color: 'rgb(116, 7, 255)'}}>Upload File - txt, pdf, docx</h2>
                                                                  {/* <p id="modal-description">This is the content of the modal.</p> */}
                                                                  {/* <h5>Upload any document below to verify your understanding of it.</h5> */}
                                                                    <div className="card w-50 mb-4" style = {{marginBottom: '4px', paddingBottom: '4px'}}>
                                                                      <div className="card-body">
                                                                        <div className="file-area">
                                                                          <input type="file" id="assignment-upload" name="assignment-upload" accept=".txt,.pdf,.docx" onChange={handleAssignmentUpload}/>
                                                                            <div className="file-dummy">
                                                                              <span className="success">{assignmentFile ? assignmentFile.name : "Please drag or upload a TXT, PDF or DOCX file here"}</span>
                                                                            </div>
                                                                        </div>
                                                                        <label for="resume_box" style = {{color: 'rgb(116, 7, 255)', marginTop: '5px'}}><i>Please check this box if the document is a resume</i></label>
                                                                        <input type="checkbox" id="resume_box" name="resume_box" value="resume_box"></input>
                                                                      </div>
                                                                    </div>

                                                                    {/* {fileProcessLoad && <LoadingComponent />} */}
                                                                    {fileProcessLoad && <div style = {{margin: '20px'}}><TextLoader></TextLoader></div>}
                                                                  <Button onClick={handleFileModalClose}
                                                                  //color='rgb(116, 7, 255)'
                                                                  style={{ 
                                                                    width:'6%',
                                                                    height: '30%', 
                                                                    padding: '5px 0px',
                                                                    color: 'rgb(116, 7, 255)', 
                                                                    //backgroundColor: 'info',
                                                                    //border: 'red', 
                                                                    border: '1px solid',
                                                                    textTransform: 'none',
                                                                    //color: 'black',
                                                                    borderRadius: '10px',
                                                                    fontSize: '15px',
                                                                    marginRight: '5px'
                                                                  }}>Back</Button>
                                                                  <Button onClick={() => handleFileSubmit(assignmentFile)}
                                                                    style={{ 
                                                                      width:'6%',
                                                                      height: '30%', 
                                                                      padding: '5px 0px', 
                                                                      backgroundColor: 'rgb(116, 7, 255)', 
                                                                      textTransform: 'none',
                                                                      color: 'white',
                                                                      borderRadius: '10px',
                                                                      fontSize: '15px'
                                                                    }}
                                                                    >Verify Doc</Button>
                                                                    <hr></hr>
                                                                    <div 
                                                                    //style = {{overflowY: 'auto', maxHeight: '80%'}}  
                                                                    >
                                                                      <h2 style = {{color: 'rgb(116, 7, 255)', fontFamily:'IBM Plex Sans'}}>Documents Dashboard</h2> <h5 style = {{color: 'gray', fontFamily:'IBM Plex Sans'}}>Uploaded documents are retained only for this current session</h5>

                                                                      {/* content for table start */}

                                                                      {Object.values(uploadedDocs).map((doc, index) => (
                                                                        <div key={doc.key}>
                                                                          <Accordion style={{ marginBottom: '4px' }} defaultExpanded = {true}> 
                                                                            <AccordionSummary
                                                                              expandIcon={<ExpandMoreIcon />}
                                                                              aria-controls="panel1-content"
                                                                              id="panel1-header"
                                                                            >
                                                                              <h4 style = {{fontFamily:'IBM Plex Sans'}}>{index + 1 + ') ' + doc.name}</h4>
                                                                            </AccordionSummary>
                                                                            {docsProcessLoad[doc.key] && (
                                                                              <div style={{ marginLeft: '20px' }}>
                                                                                <Loader />
                                                                              </div>
                                                                            )}
                                                                            <AccordionDetails>
                                                                              <h4 style={{ color: 'rgb(116, 7, 255)', fontFamily:'IBM Plex Sans' }}>Document Summary Report</h4>         
                                                                              <button onClick={() => handleDocReportDownload(doc.key, doc.name)}>Download Report</button>

                                                                              <div style={{display: 'flex', fontFamily: 'IBM Plex Sans'}}>
                                                                                    <h5 style = {{marginRight: '15px'}}>Total Facts: {doc.docTranscriptsIndices.length}</h5>
                                                                                    <h5 style = {{marginRight: '15px'}}>True: {getDocTrueCount(doc.key)}</h5>
                                                                                    <h5 style = {{marginRight: '15px'}}>False: {getDocFalseCount(doc.key)}</h5>
                                                                                    <h5 style = {{marginRight: '15px'}}>Unverifiable: {getDocUnverifiableCount(doc.key)}</h5>
                                                                                    <h5 style = {{marginRight: '15px'}}>Queued: {getDocQueueCount(doc.key)}</h5>
                                                                                </div>
                                                                              {doc != null && docTranscripts.length > 0 && (
                                                                                <TableContainer component={Paper}>
                                                                                  <Table aria-label="transcripts table" sx = {{border: '2px solid purple'}}>
                                                                                    <TableHead>
                                                                                      <TableRow style = {{backgroundColor : 'rgba(116, 7, 255, 0.2)' }}>
                                                                                        <TableCell style = {{color:'rgb(116, 7, 255)', width: '30%'}} sx = {{borderBottom: '1px solid purple'}}><strong>Claim/Fact</strong></TableCell>
                                                                                        <TableCell style = {{color:'rgb(116, 7, 255)', width: '10%'}} sx = {{borderBottom: '1px solid purple'}}><strong>Classification</strong></TableCell>
                                                                                        <TableCell style = {{color:'rgb(116, 7, 255)', width: '30%'}} sx = {{borderBottom: '1px solid purple'}}><strong>Assessment</strong></TableCell>
                                                                                        <TableCell style = {{color:'rgb(116, 7, 255)', width: '20%'}} sx = {{borderBottom: '1px solid purple'}}><strong>Disambiguation</strong></TableCell>
                                                                                        <TableCell style = {{color:'rgb(116, 7, 255)', width: '10%'}} sx = {{borderBottom: '1px solid purple'}}><strong>Sources</strong></TableCell>
                                                                                      </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                      {docTranscripts.map((transcript, index) => (
                                                                                        transcript.docKey === doc.key && (
                                                                                          <TableRow key={transcript.id}
                                                                                            onMouseOver={() => setHoveredId(transcript.id)}
                                                                                            onMouseLeave={() => setHoveredId(null)}
                                                                                            //style={{ backgroundColor: getRowBackgroundColor(transcript.verdict) }}
                                                                                            >
                                                                                                <TableCell sx = {{borderBottom: '1px solid purple', fontFamily : 'IBM Plex Sans'}}>{transcript.transcript}</TableCell>
                                                                                                <TableCell sx = {{borderBottom: '1px solid purple'}}>
                                                                                                  {transcript.isPickedNow ? (
                                                                                                    <Skeleton animation="pulse" sx={{ bgcolor: 'rgb(116, 7, 255)' }}  />
                                                                                                  ) : transcript.isQueuedNow ? (
                                                                                                    <span className="blinking-text"><Skeleton animation="wave" /></span>
                                                                                                  ) : (
                                                                                                    <Badge color={getRowBadgeColor(transcript.verdict)} style = {{justifyContent: 'center', marginLeft: '10px'}} badgeContent={transcript.verdict}></Badge>
                                                                                                  )}
                                                                                                </TableCell>
                                                                                                <TableCell sx = {{borderBottom: '1px solid purple', fontFamily : 'IBM Plex Sans'}}>
                                                                                                  {transcript.isPickedNow ? (
                                                                                                    <Skeleton animation="pulse" sx={{ bgcolor: 'rgb(116, 7, 255)' }}  />
                                                                                                  ) : transcript.isQueuedNow ? (
                                                                                                    <span className="blinking-text"><Skeleton animation="wave"  /></span>
                                                                                                  ) : (
                                                                                                    transcript.explanation
                                                                                                  )}
                                                                                                </TableCell>
                                                                                                <TableCell sx = {{borderBottom: '1px solid purple', fontFamily : 'IBM Plex Sans'}}>
                                                                                                  {transcript.isPickedNow ? (
                                                                                                    <Skeleton animation="pulse" sx={{ bgcolor: 'rgb(116, 7, 255)' }}  />
                                                                                                  ) : transcript.isQueuedNow ? (
                                                                                                    <span className="blinking-text"><Skeleton animation="wave"  /></span>
                                                                                                  ) : (
                                                                                                    transcript.disambiguation
                                                                                                  )}
                                                                                                </TableCell>
                                                                                                <TableCell sx = {{borderBottom: '1px solid purple', fontFamily : 'IBM Plex Sans', maxHeight: '80px', overflowY: 'auto'}}>
                                                                                                  {transcript.isPickedNow ? (
                                                                                                    <Skeleton animation="pulse" sx={{ bgcolor: 'rgb(116, 7, 255)' }} />
                                                                                                  ) : transcript.isQueuedNow ? (
                                                                                                    <span className="blinking-text"><Skeleton animation="wave" /></span>
                                                                                                  ) : (
                                                                                                    //JSON.stringify(transcript.sources, null, 2)
                                                                                                    <div style={{ maxHeight: '100px', overflowY: 'scroll',  border: '1px solid #ccc', scrollbarWidth: 'thin', scrollbarColor: 'rgba(116, 7, 255, 0.6) white' }}>
                                                                                                        {transcript.sources.map((item, index) => (
                                                                                                          <div key={index}>
                                                                                                            <ul>
                                                                                                              {item.sources.map((source, sourceIndex) => (
                                                                                                                <li key={sourceIndex}><a href={source}>{source}</a></li>
                                                                                                              ))}
                                                                                                            </ul>
                                                                                                          </div>
                                                                                                        ))}
                                                                                                      </div>
                                                                                                  )}
                                                                                                </TableCell>
                                                                                          </TableRow>
                                                                                        )
                                                                                      ))}
                                                                                    </TableBody>
                                                                                  </Table>
                                                                                </TableContainer>
                                                                              )}
                                                                            </AccordionDetails>
                                                                          </Accordion>
                                                                        </div>
                                                                      ))}

                                                                      {/* content for table end */}

                                                                    </div>
                                                                </div>
                                                              </Fade>
                                                            </Modal>
                                                          </div>
                                                        </div>
                                                      ),
                                                    }}
                />                
              </Grid>
              <Grid item xs={0} md={1}>
              </Grid>
           
        </div>
        </div>          
        )}                                      
        </div>
    </div>

    
  );
};

export default App;
