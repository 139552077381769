import React, { useEffect, useState, memo } from 'react';
import { Button, Collapse, Typography, Card, CardContent, Badge } from '@mui/material';
import ThumbsUpDownButtons from './thumbsupordown';
import LoadingComponent from './loadingComponent';
import LoadingComponent2 from './loadingComponentv2';
import TextField from '@mui/material/TextField';
import { purple } from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import './tooltip.css';
import {marked} from 'marked';
import ScatterPlot from './plot';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { createAndCheckTask } from './runasyncqueries';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';

const renderer = new marked.Renderer();

renderer.link = function(href, title, text) {
  return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};

const ExpandComponentUpdated = memo(({ claim, email, handleThumbsResponse, sendFeedback, process,done, setDone,source}) => {
  const [expanded, setExpanded] = useState(true);// was false. changing to true to fix the card rendering glitch for documents
  const [expandedGroups, setExpandedGroups] = useState({});
  const [value, setValue] = React.useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [assessment, setAssessment] = useState("")
  const [sources, setSources] = useState([])
  const [classification, setClassification] = useState([])
  const [evidence,setEvidence] =useState({})
  const [disambiguation, setDisambiguation] = useState("")
  const [bias, setBias] = useState(null)
  const [id, setId] = useState(null)
  const [message, setMessage] = useState("")
  const [ready, setReady] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm')); // Adjust 'sm' as needed based on your breakpoints

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  const addMessage = (newMessage) => {
    setMessage(prevMessage => prevMessage ? `${prevMessage}\n${newMessage}` : newMessage);
  };


  function makeUrlsClickable(text) {
    return text
    // const urlPattern = /(https?:\/\/[^\s]+)/g;
    // return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
  }


  const toggleExpansion = (support) => {
    setExpandedGroups({
      ...expandedGroups,
      [support]: !expandedGroups[support]
    });
  };


  const handleFormExpandClick = () => {
    setShowTextField(true);
  };

  useEffect(() => {
      
      const fetchData = async () => {
      
        if (process){
          const uniqueId = uuidv4();
          const userEmail = email ? email : "NA";
          const timestamp = Date.now();
          const location = "";
    
          // setRequestIds(prevIds => [...prevIds, uniqueId]);
    
          try {
            const taskPromise = createAndCheckTask(claim, location, timestamp, userEmail, "speaker", source, "inline", addMessage);

            const timeoutPromise = new Promise((resolve, reject) => setTimeout(() => {
              resolve({ 
                    output: {
                        output: [],
                        queries: [],
                        disambiguation: "",
                        status: "error",
                        overall_assessment: "Query failed. Please try again",
                        Classification: "",
                        sources: [],
                        question: claim,
                        location: location,
                        userEmail: userEmail,
                        type: "query",
                        evidence: {},
                        id: 0
                    }, 
                    error: "Task timeout. Please try again.", 
                    loading: false 
                });
            }, 60000));

            const { output, error, loading } = await Promise.race([taskPromise, timeoutPromise]);
            
            console.log({output})

            console.log(output)
            setAssessment(output.overall_assessment.replace(/<[^>]*>/g, ''))
            setClassification(output.Classification)
            setEvidence(output.evidence)
            setSources(output.sources)
            setReady(true)
            setBias(output.bias)
            setDone(claim,output.Classification)
            if (error) {
              console.error('Error fetching data:', error);
              return;
            }
    
    
          } catch (err) {
            console.error('Error fetching data:', err);
          }
        }
    };

    fetchData();
  }, [claim,process]); // dependencies array


  useEffect(()=>{
    console.log({"Process triggered":claim , process:process})
  },[process])

  async function  sendFeedback () {
      console.log(id)
      try {
        // const apiUrl = 'http://127.0.0.1:5000/SendDetailedFeedback';
        const apiUrl = 'https://facticity-backend-a4xncz5jja-as.a.run.app/SendDetailedFeedback';
        const requestBody = { id: {id: id}, response: value}; 
  
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }else{
          console.error('Success');
        }
  
        const data = await response.json();
        return data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
    //sample
    // Function to determine the color based on the classification
    const getColor = (classification) => {
      switch (classification) {
        case 'True':
          return 'success';
        case 'Processing':
          return 'warning';
        case 'False':
          return 'error';
        case 'Inconclusive':
          return 'warning';
        case 'Unverifiable':
          return 'warning';
        case '':
          return 'info';
        default:
          return 'info'; // Default to error color for unknown classifications
      }
    };

    const badgeColor = getColor(classification);


  useEffect(() => {
    if (ready === true) {
      setExpanded(true);
    }
  }, [ready]);

  const groupedSources = sources.reduce((acc, source, index) => {
    // Normalize the support to handle any unexpected cases
    const support = source.support || 'Unknown';

    // Initialize the group if it doesn't exist
    if (!acc[support]) {
      acc[support] = [];
    }

    // Add the current source to the appropriate group
    acc[support].push(...source.sources.map(link => link.endsWith(',') ? link.slice(0, -1) : link));

    return acc;
  }, {});

  const badgeContent = classification === 'Inconclusive' ? 'Unverifiable' : classification;

  return (
    <Card variant="outlined" style={{ marginBottom: '10px', fontFamily: 'IBM Plex Sans', }}>
      <CardContent >
        {/* <Badge color = "secondary" badgeContent={classification}> */}
          <div style = {{display: 'flex', alignItems: 'center' }}>
          <Button
            //color='secondary'
            onClick={handleExpandClick}
            //fullWidth
            variant="contained"
            border = "5px solid #0A0A0A"
            style={{
              width: '90%',
              //backgroundColor: color,
              backgroundColor: 'white',
              border: '5px solid #0A0A0A',
              justifyContent: "flex-start",
              textAlign: 'left',
              boxShadow: 'none',
              textTransform: 'none',
              border: 'none',
              color: 'black', // Set the text color to black
            }}
          >
          <Typography sx={{ fontFamily: 'IBM Plex Sans', fontSize: '15px' }}>
            {claim}
          </Typography>

          </Button>
          <center>
            <Badge color={badgeColor} badgeContent={badgeContent} style = {{marginBottom: '10px'}}
            
            sx={{ 
              '& .MuiBadge-badge': {
                padding: '15px', 
                fontSize: '15px'  // Adjust the font size here
              }
            }}></Badge>
            </center>
          {/* <Badge color={badgeColor} badgeContent={classification}></Badge> */}
          </div>

        <Typography variant="body1">
            {process && !done && (
              <div>
                <Typography component="pre" style={{ whiteSpace: 'pre-wrap', marginRight: '8px' }}>
                      {"Verifying"}
                </Typography>
              <div>
                {message.split('\n').map((msg, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <CircularProgress size={10} sx={{ color: 'black', marginLeft: '10px' }} />
                    <Typography component="pre" style={{ whiteSpace: 'pre-wrap', marginRight: '8px' }}>
                      {msg}
                    </Typography>
                  </div>
                ))}
              </div>
              </div>
            )}
        </Typography>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {ready === true && (
            <CardContent style = {{border: '1.5px solid #0A0A0A', borderRadius: '20px', fontSize: '15px'}}>
              {/* <p style={{ fontSize: '16px', fontWeight: 'bold', color: badgeColor }} color = {{badgeColor}}>
                {classification}
              </p> */}
              <div>

                <div>
                  {(bias && Array.isArray(bias) && bias.length > 1) ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={7}>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{ __html: assessment ? marked(assessment,{renderer}) : 'Query failed. Please try again' }}
                          style={{ fontFamily: 'IBM Plex Sans', fontSize: '15px' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        {!isXs && <br />}
                        { disambiguation !== "" && (
                          <div>
                            <Alert severity="info" sx={{ fontFamily: 'IBM Plex Sans' }}>
                                <Typography
                                  variant="body2"
                                  dangerouslySetInnerHTML={{ __html: disambiguation ? marked("Let's disambiguate: "+disambiguation) : 'Query failed. Please try again' }}
                                  style={{ fontFamily: 'IBM Plex Sans', fontSize: '15px' }}
                                />
                              {/* Let's disambiguate: {marked(disambiguation)} */}
                            </Alert>
                            <br></br>
                          </div>
                        )}
                        {/* <ScatterPlot claim={claim} data={bias}/> */}
                        <br></br>
                          {sources.length > 0 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {Object.entries(groupedSources).map(([support, links], index) => (
                                  <div key={index}>
                                    {/* <h5>{support}</h5> */}
                                    {links.slice(0, expandedGroups[support] ? links.length : 2).map((link, linkIndex) => (
                                      <p key={linkIndex} style={{ margin: '0 5px 5px 0' }}>
                                        <div className="tooltip">
                                          <a 
                                            href={link} 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            style={{ fontSize: 'small' }}
                                          >
                                            {linkIndex+1+". "+link}
                                          </a>
                                            <span className="tooltiptext">
                                              {evidence[link] ? evidence[link].substring(0, 200) : 'No evidence available'}
                                            </span>

                                        </div>
                                      </p>
                                    ))}
                                    {links.length > 2 && (
                                      <Button 
                                      style={{
                                        backgroundColor: "#fff", // Darker purple button
                                        color: purple[200], // White text
                                        boxShadow: 'none' // Ensure button is flat
                                      }}
                                      onClick={() => toggleExpansion(support)}>
                                        {expandedGroups[support] ? 'Hide Links' : 'Show More Links'}
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>

                          )}
                      </Grid>
                    </Grid>       
                  ) : (
                    <div>
                      <Typography 
                        variant="body2" 
                        dangerouslySetInnerHTML={{ __html: assessment ? marked(assessment,{renderer}) : 'Query failed. Please try again' }} 
                        style={{ fontFamily: 'IBM Plex Sans', fontSize: '15px' }} 
                      />
                      { disambiguation !== "" && (
                            <Alert severity="info" sx={{ fontFamily: 'IBM Plex Sans' }}>
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{ __html: disambiguation ? marked("Let's disambiguate: "+disambiguation) : 'Query failed. Please try again' }}
                              style={{ fontFamily: 'IBM Plex Sans', fontSize: '15px' }}
                            />
                          {/* Let's disambiguate: {marked(disambiguation)} */}
                        </Alert>
                        
                      )}
                       {sources.length > 0 && (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {Object.entries(groupedSources).map(([support, links], index) => (
                              <div key={index}>
                                {/* <h5>{support}</h5> */}
                                {links.slice(0, expandedGroups[support] ? links.length : 2).map((link, linkIndex) => (
                                  <p key={linkIndex} style={{ margin: '0 5px 5px 0' }}>
                                    <div className="tooltip">
                                      <a 
                                        href={link} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        style={{ fontSize: 'small' }}
                                      >
                                        {linkIndex+1+". "+link}
                                      </a>
                                        <span className="tooltiptext">
                                          {evidence[link] ? evidence[link].substring(0, 200) : 'No evidence available'}
                                        </span>

                                    </div>
                                  </p>
                                ))}
                                {links.length > 2 && (
                                  <Button 
                                  style={{
                                    backgroundColor: "#fff", // Darker purple button
                                    color: purple[200], // White text
                                    boxShadow: 'none' // Ensure button is flat
                                  }}
                                  onClick={() => toggleExpansion(support)}>
                                    {expandedGroups[support] ? 'Hide Links' : 'Show More Links'}
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>

                      )}
                    </div>
                  )}
                </div>

              <br />
             
              {(classification!="") && (
                <div>
                  <Typography variant="body2">How did I do?</Typography>
                  <ThumbsUpDownButtons id={id} handleThumbsResponse={handleThumbsResponse} />
                  {showTextField ? (
                  <React.Fragment>
                    <TextField
                      multiline
                      fullWidth
                      variant="outlined"
                      value={value}
                      onChange={handleChange}
                      placeholder="Type your feedback..."
                      minRows={2}
                      maxRows={2}
                      style={{
                        backgroundColor: "#fff", // Darker purple button
                        color: '#0A0A0A', // White text
                        boxShadow: 'none' // Ensure button is flat
                      }}
                    />
                    <Button
                      onClick={sendFeedback}
                      style={{
                        backgroundColor: "#fff", // Darker purple button
                        color: '#0A0A0A', // White text
                        boxShadow: 'none' // Ensure button is flat
                      }}>
                    Send
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button 
                      onClick={handleFormExpandClick}
                      style={{
                        backgroundColor: "#fff", // Darker purple button
                        color: purple[200], // White text
                        boxShadow: 'none' // Ensure button is flat
                      }}
                      >
                      Provide Feedback
                    </Button>
                  </React.Fragment>

                )}
                </div>
              )
              }
              </div>

            </CardContent>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
});

export default ExpandComponentUpdated;

